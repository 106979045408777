// SearchBar.jsx
import React from "react";
import InfoCircle from "./InfoCircle";

const SearchBar = ({
  searchQuery,
  onSearchChange,
  text = "",
  searchBy,
  position,
  disabled,
  searchTerm,
  handleSearch
}) => {
  const isSmallScreen = window.innerWidth < 768;
  return (
    <div className="flex items-center justify-end lg:w-1/5">
      {/* if the passed value is left, InfoCircle is displayed to the left of the SearchBar */}
      {!isSmallScreen && position === "left" && (
        <InfoCircle type="search" searchBy={searchBy} />
      )}
      {isSmallScreen && position !== "left" && (
        <InfoCircle type="search" searchBy={searchBy} />
      )}
      {isSmallScreen && position === "left" && (
        <InfoCircle type="search" searchBy={searchBy} />
      )}

      <input
        disabled={disabled}
        type="text"
        placeholder={`Pretražite ${text}...`}
        value={searchQuery}
        onChange={onSearchChange}
        className="block w-full px-5 py-3 ml-1 text-gray-900 border border-gray-300 rounded-md cursor-pointer bg-gray-50 hover:border-primary-750 sm:text-sm dark:bg-primary-800 dark:border-primary-750 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
      {position !== "left" && !isSmallScreen && (
        <InfoCircle type="search" searchBy={searchBy} />
      )}
    </div>
  );
};

export default SearchBar;
