import React from "react";
import { Form, Field } from "react-final-form";
import {
  composeValidators,
  requiredFieldValidation,
  mustBeString,
  checkPasswordRequirements,
  areDatesConsist,
  validateMultipleSelection,
  checkDropdownSelection,
} from "../../utils/helpers/validations";
import {
  InputField,
  RenderDatePicker,
  Dropdown,
  MultiSelectInput,
  PasswordInputField,
  CustomMultiSelect,
} from "../common/FormComponents";
import { useModal } from "../../context/ModalContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { DateConsistencyError } from "../../errors/FormValidationErrorField";
import { useDispatch, useSelector } from "react-redux";
import { postProjectDataAsync } from "../../api/services/projectService";
import { postTask, taskService } from "../../api/services/taskService";
import { postRequirement } from "../../api/services/requirementService";
import FileUpload from "../common/FileUpload";
import { filesService } from "../../api/services/filesService";
import {
  addControl,
  addControlOwner,
  changeControlOwner,
  changeControlStatus,
  toggleControlStatus,
} from "../../api/slices/controlSlice";
import PopupFileUpload from "./PopupFileUpload";
import { SubmitButton } from "../common/Button";
import { createUser } from "../../api/slices/memberSlice";
import { addTaskToControl, fetchTasksForControl } from "../../api/slices/tasksForControlSlice";
import { updatePass } from "../../api/slices/settingsSlice";
import { uploadTaskFile } from "../../api/slices/taskSlice";
import PopupSuccess from "./PopupSuccess";
import Loader from "../common/Loader";
import messages from "../../assets/strings/errorMessages";
import { fetchMembers } from "../../api/services/memberService";
import { getStandardById } from "../../api/slices/standardSlice";

const ModalAdd = ({ data, isOpen, index }) => {
  //Setting default values for options and allChoices since they are going to be empty in some cases
  //Options is passed for dropDown element
  //allValues are passed as multiple choice
  const {
    content,
    options = "",
    allChoices = [],
    type = "",
    id = "",
    tid = "",
    buttonType = "",
    members = "",
    removeAssignees = "",
  } = data;
  const { openModal, closeModal, closeAllModals } = useModal();
  const { file } = useSelector((state) => state.files);
  const { message, loading, error, successfullUpload } = useSelector(
    (state) => state.control
  );
  const { successCreate, loadingMembers } = useSelector(
    (state) => state.member
  );

  // const loading = useSelector((state) => state[type].loading);
  // const error = useSelector((state) => state[type].error);
  const dispatch = useDispatch();

  //..
  if (!isOpen) return null;

  const transformArray = (array) => {
    return array.map((item) => ({ value: item.value }));
  };

  //Function which is being called after data add is confirmed..
  //DISPATCH POST BASED ON TYPE PASSED
  const add = async (values) => {
    const { name, description, responsiblePerson, startDate, endDate } = values;
    //===============create new task=================
    if (type === "addTaskFile") {
      const { name, description, assignees, status, startDate, endDate } =
        values;
      // if (file) {
      await filesService.uploadFile({
        id: id,
        file,
        name,
        description,
        assignees,
        status,
        startDate,
        endDate,
      }).then(()=>dispatch(fetchTasksForControl({ id })));
      // }
    }
    //========add file to created task===================
    if (type === "addFileToTask") {
      const { name } = values;
      console.log("VALUESSSSSSSSSSSSSS", values);

      if (file) {
        console.log("tid", tid);

        await taskService.uploadTaskFile({
          tid,
          file,
          name,
        });
      }
    }

    if (type === "addDocument") {
      console.log("VALUES", values);
      if (file) {
        console.log("file", file);
        await filesService.uploadFile({
          file,
          name,
          description,
          responsiblePerson,
        });
      }
    }
    //add task without file
    // if (type === "addTask") {
    //   dispatch(addTaskToControl({ values: values, id: id }));
    // }

    if (type === "addControl") {
      dispatch(addControl(values));
    }

    if (type === "createControl") {
      dispatch(addControl(values)).then(() =>
        dispatch(getStandardById({ id }))
      );
    }

    if (type === "addEmployee") {
      dispatch(addControl(values));
    }
    if (type === "createUser") {
      dispatch(createUser(values));
      dispatch(fetchMembers());
    }
    if (type === "addRationale") {
      dispatch(toggleControlStatus({ data: { values: values, id: id } }));
    }
    if (type === "removeRationale") {
      dispatch(toggleControlStatus({ data: { values: values, id: id } }));
    }
    if (type === "changeControlOwner") {
      dispatch(
        changeControlOwner({
          data: {
            values: {
              newAssignees: values,
              removeAssignees: "" + removeAssignees,
            },
            id: id,
          },
        })
      );
    }
    if (type === "addAControlOwner") {
      dispatch(addControlOwner({ data: { values: values }, id: id }));
    }
    if (type === "updateControl") {
      dispatch(changeControlStatus({ data: { values: values, id: id } }));
    }
    if (type === "updatePassword") {
      dispatch(updatePass({ data: { values: values } }));
    }

    //--------------------Dispatch action for making project --------------------
    if (type === "project") {
      //  remove label
      const modifiedData = {
        ...values,
        team: values.team.map((item) => item.value),
      };
      dispatch(postProjectDataAsync(modifiedData));
      console.log(modifiedData);
    }
    //--------------------Dispatch action for making new task --------------------
    else if (type === "task") {
      if (!!id) {
        const modifiedData = {
          ...values,
          demandId: id,
          team: values.team.map((item) => item.value),
        };
        dispatch(postTask(modifiedData));
      }
    }
    //--------------------Dispatch action for creating new member --------------------
    else if (type === "member") {
      // dispatch call for member
    }
    //--------------------Dispatch action for creating new requirement --------------------
    else if (type === "requirement") {
      const valuesWithID = { projectId: id, ...values };
      console.log(valuesWithID);
      dispatch(postRequirement(valuesWithID));
    }
  };

  //On form submit, do action defined below
  const onSubmit = async (formValues) => {
    console.log("ON SUBMIT??");
    //refresh page on submit
    const refreshPage = () => {
      // window.location.reload();
    };

    openModal("Modal1", {
      text: "Potvrda",
      close: closeAllModals,
      confirm: () => {
        add(formValues).catch((error) => {
          console.error("Error adding data:", error);
        });
      },
      type: "success",
    });
  };

  //Map initial values to key value pair and pass them as Initial Values, this would
  //will enable appropriate form reset
  //Reduce passed content to one array so it can be used as initial values
  //convert this array into an object where the keys are the field names, and the values are the initial values:
  const initialValues = content.reduce((acc) => {
    return acc;
  }, {});

  const renderData = () => {
    return (
      <>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="py-10 text-gray-400 px-7 lg:px-14 md:px-14"
            >
              {content.map((item, index) => (
                <>
                  {/* Render field names on Serbian.. due to validate function it had to be passed as startDate and endDate */}
                  <div key={item.name} className="relative">
                    <p>{item.label}</p>
                    {/* NA OSNOVU TIPA POLJA MORACE DA SE GENERISE FIELD, NE MOZE SVE NA INPUT, NPR DATUM, rola, tip...*/}
                    {item.type === "text" ? (
                      <Field
                        name={item.name}
                        component={InputField}
                        type="text"
                        required={true}
                        validate={composeValidators(
                          mustBeString,
                          requiredFieldValidation
                        )}
                        icon={true}
                      />
                    ) : item.type === "password" ? (
                      <Field
                        name={item.name}
                        component={PasswordInputField}
                        type="text"
                        required={true}
                        validate={composeValidators(
                          requiredFieldValidation,
                          checkPasswordRequirements
                        )}
                        icon={true}
                      />
                    ) : item.type === "email" ? (
                      <Field
                        name={item.name}
                        component={InputField}
                        type="text"
                        required={true}
                        validate={composeValidators(requiredFieldValidation)}
                        icon={true}
                      />
                    ) : item.type === "dragAndDrop" ? (
                      <Field
                        name={item.name}
                        component={FileUpload}
                        type="text"
                        required={true}
                        validate={composeValidators(requiredFieldValidation)}
                      />
                    ) : item.type === "fileUpload" ? (
                      <Field
                        name={item.name}
                        component={PopupFileUpload}
                        tid={tid}
                        type="text"
                        required={true}
                        validate={composeValidators(requiredFieldValidation)}
                      />
                    ) : item.type === "dropDown" ? (
                      <Field
                        name={item.name}
                        component={Dropdown}
                        type="text"
                        options={options}
                        required={true}
                        validate={checkDropdownSelection}
                        text="Izaberite"
                      />
                    ) : item.type === "multipleSelect" ? (
                      <Field
                        name={item.name}
                        component={CustomMultiSelect}
                        type="text"
                        members={members}
                        required={true}
                        validate={checkDropdownSelection}
                        text="Izaberite"
                      />
                    ) : item.type === "date" ? (
                      <div className="relative">
                        <Field
                          name={item.name}
                          component={RenderDatePicker}
                          required={true}
                          validate={(value, allValues) =>
                            areDatesConsist(value, allValues)
                          }
                        />
                        <DateConsistencyError name={item.name} />
                      </div>
                    ) : (
                      item.type === "multiselectinput" && (
                        <>
                          <Field
                            name={item.name}
                            component={MultiSelectInput}
                            allChoices={allChoices}
                            required={true}
                            validate={validateMultipleSelection}
                            errorMessage="Select at least one option"
                          />
                        </>
                      )
                    )}
                  </div>
                </>
              ))}
              <div className="">
                <SubmitButton type={buttonType} />
              </div>
            </form>
          )}
        />
      </>
    );
  };

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full overflow-y-scroll bg-black bg-opacity-50">
      <div className="relative w-full max-w-3xl max-h-full p-4 animate-control">
        <div className="relative w-full rounded-lg shadow bg-primary-50 dark:bg-primary-800">
          {/* <CloseModalButton closeModal={() => closeModal(index)} /> */}
          <button
            type="button"
            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={() => closeModal(index)}
          >
            <span className="w-6 ">
              <XMarkIcon />
            </span>
          </button>
          {renderData()} {(loading || loadingMembers) && <Loader />}
          {(successfullUpload || successCreate) && (
            <PopupSuccess label={messages.uploadSuccess} type="success" />
          )}
          {error && <PopupSuccess label={messages.uploadError} type="error" />}
        </div>
      </div>
    </div>
  );
};

export default ModalAdd;
