import apiService from "./apiService";

// get Tasks for control by control id
const fetchTasksForControl = async (id) => {
  const response = await apiService.getData(`controls/${id}/tasks`);
  const tasksForControl = response?.data?.tasks;
  console.log("task for control", tasksForControl);
  return tasksForControl;
};

// add task to control (without file)
const addTask = async (id, data) => {
  const response = await apiService.postData(`controls/${id}/tasks`, data);

  return response;
};

export const tasksForControlService = {
  fetchTasksForControl,
  addTask,
};
