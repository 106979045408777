import apiService from "./apiService";

//GET data for dashboard projectCount,employeesCount,documentCount
const fetchDashboard = async () => {
  const response = await apiService.getData("company/dashboard");
  const dashboard = response?.data?.dashboard;
  return dashboard;
};

export const dashboardService = {
  fetchDashboard,
};
