import React, { useEffect, useRef } from "react";
import Dropzone from "dropzone";

import "dropzone/dist/dropzone.css"; // import dropzone css style
import { useDispatch } from "react-redux";
import { addFile } from "../../api/slices/filesSlice";

const FileUpload = () => {
  const dropzoneRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    // Initializing Dropzone
    const dropzone = new Dropzone(dropzoneRef.current, {
      url: "/upload",
      autoProcessQueue: false,
      addRemoveLinks: false, // Allows adding links to remove files
      dictDefaultMessage: ` 
      <div class="flex items-center justify-center w-full cursor-pointer bg-primary-55 dark:bg-primary-800">
        <div class="flex flex-col items-center justify-center ">
          <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
          </svg>
          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
            Kliknite za otpremanje ili prevucite fajlove ovde
          </p>
        </div>
      </div>
    `, // Setting the message that will be displayed in the Dropzone container

      removedfile: function (file) {
        file.previewElement.remove(); // Removes file display from Dropzone
      },
    });

    // adding an element for the button to remove the file in Dropzone
    dropzone.on("addedfile", (file) => {
      const removeButton = Dropzone.createElement(
        '<button class="dz-remove-button absolute top-0 right-0 p-2 text-gray-500 font-bold text-sm rounded-md hover:bg-gray-300">X</button>'
      );
      const fileContainer = file.previewElement.querySelector(".dz-details");
      fileContainer.appendChild(removeButton);

      dispatch(addFile({ file }));

      // add an event listener for removing the file
      removeButton.addEventListener("click", () => {
        dropzone.removeFile(file); // removes a file from Dropzone
      });
    });

    // When the component is terminated,destroy the Dropzone instance
    return () => {
      dropzone.removeAllFiles(true); // removes all added files
      dropzone.destroy(); // destroys the dropzone instance
    };
  }, []);

  const onSubmit = (values) => {
    //data handling logic
    console.log(values);
  };

  return (
    <>
      <div className="rounded-md bg-primary-50 dark:bg-primary-800">
        {/* <h2 className="mb-4 text-lg font-semibold">Otpremanje dokaza</h2> */}
        {/* <Form
        onSubmit={onSubmit}
        render={() => <form className="w-full "></form>}
      /> */}

        <div className="mx-auto">
          <div className="flex flex-col items-center justify-center">
            <div
              ref={dropzoneRef}
              className="w-full p-4 mb-4 border-2 border-gray-300 border-dashed rounded-md dropzone dark:border-gray-500 bg-primary-55 dark:bg-primary-800"
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
