import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { completedTaskService } from "../services/completedTasksDashboardService";

// get all completed tasks for dashboard graph
export const fetchTasksDashboard = createAsyncThunk(
  "/completedTasksDashboard/get",
  async (thunkAPI) => {
    try {
      return await completedTaskService.fetchTasksDashboard();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;

      return thunkAPI?.rejectWithValue(message);
    }
  }
);

const completedTasksSlice = createSlice({
  name: "completedTasks",
  initialState: {
    completedTasksData: null,
    loadingDashboard2: false,
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasksDashboard.pending, (state) => {
        state.loadingDashboard2 = true;
        state.error = null;
        state.message = null;
      })
      .addCase(fetchTasksDashboard.fulfilled, (state, action) => {
        state.loadingDashboard2 = false;
        state.completedTasksData = action.payload;
      })
      .addCase(fetchTasksDashboard.rejected, (state, action) => {
        state.loadingDashboard2 = false;
        state.error = action.error.message;
      });
  },
});

export default completedTasksSlice.reducer;
