import React from "react";
import CirclePieChart from "../../common/charts/CirclePieChart";

const ReadinessScore = () => {
  return (
    <div className="min-w-full min-h-full p-6 rounded-md shadow bg-primary-55 dark:bg-primary-800 ">
      <p className="flex overflow-visible font-bold">Napredak</p>
      <div className="flex flex-row items-center justify-center w-full overflow-visible h-80">
        <CirclePieChart />
      </div>
    </div>
  );
};

export default ReadinessScore;
