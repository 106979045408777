import React, { useState, useEffect, useRef } from "react";
import {
  ArrowDownTrayIcon,
  TrashIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useModal } from "../../context/ModalContext";
import "dropzone/dist/dropzone.css"; // import dropzone css style
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "dropzone";
import {
  deleteTaskFile,
  getTaskById,
  uploadTaskFile,
} from "../../api/slices/taskSlice";
import { addFile } from "../../api/slices/filesSlice";
import PopupSuccess from "./PopupSuccess";
import { formatDateTime } from "../../utils/helpers/formatDate";

const PopupFileUpload = (taskIndex) => {
  const dropzoneRef = useRef();
  const [message, setMessage] = useState("");
  const { status, error } = useSelector((state) => state.task);
  const { taskDetails } = useSelector((state) => state.task);
  const dispatch = useDispatch();

  console.log("task data u popup", taskDetails);

  useEffect(
    () => {
      if (!taskDetails) {
        dispatch(getTaskById({ id: taskIndex.tid }));
      }
    },
    { taskDetails }
  );

  useEffect(() => {
    if (status === "succeeded") {
      setMessage("Upload successful!");
    } else if (status === "failed") {
      setMessage(`An error occurred: ${error}`);
    }
  }, [status, error]);

  useEffect(() => {
    // Initializing Dropzone
    const dropzone = new Dropzone(dropzoneRef.current, {
      url: "/upload",
      autoProcessQueue: false,
      addRemoveLinks: false, // Allows adding links to remove files
      dictDefaultMessage: ` 
      <div class="flex items-center justify-center w-full cursor-pointer bg-primary-55 dark:bg-primary-800">
        <div class="flex flex-col items-center justify-center ">
          <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
          </svg>
          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
            Kliknite za otpremanje ili prevucite fajlove ovde
          </p>
        </div>
      </div>
    `, // Setting the message that will be displayed in the Dropzone container

      success: (file) => {
        dispatch(uploadTaskFile({ data: { file } }));
      },
      error: (file, response) => {
        setMessage("An error occurred. Please try again.");
      },
      removedfile: function (file) {
        file.previewElement.remove();
      },
    });

    // adding an element for the button to remove the file in Dropzone
    dropzone.on("addedfile", (file) => {
      console.log("File added", file);
      const removeButton = Dropzone.createElement(
        '<button class="dz-remove-button absolute top-0 right-0 p-2 text-gray-500 font-bold text-sm rounded-md hover:bg-gray-300">X</button>'
      );
      const fileContainer = file.previewElement.querySelector(".dz-details");
      fileContainer.appendChild(removeButton);

      dispatch(addFile({ file }));

      // add an event listener for removing the file
      removeButton.addEventListener("click", () => {
        dropzone.removeFile(file); // removes a file from Dropzone
      });
    });

    // When the component is terminated,destroy the Dropzone instance
    return () => {
      dropzone.removeAllFiles(true); // removes all added files
      dropzone.destroy(); // destroys the dropzone instance
    };
  }, []);

  const { openModal, closeModal } = useModal();
  return (
    <>
      <div className="w-full bg-primary-55 dark:bg-primary-800 ">
        <div className="flex flex-col items-center justify-center">
          <div
            ref={dropzoneRef}
            className="w-full p-4 mb-4 border-2 border-gray-300 border-dashed rounded-md dropzone dark:border-gray-500 bg-primary-55 dark:bg-primary-800"
          ></div>
          {message && <PopupSuccess label={message} />}
        </div>

        <div className="rounded-md bg-primary-50 dark:bg-primary-800">
          <div className="mt-4 overflow-y-auto max-h-48">
            {taskDetails?.task?.files?.map((file, index) => (
              <div
                key={index}
                className="flex flex-col p-2 mb-2 rounded-md bg-slate-100 dark:bg-slate-700"
              >
                <div className="flex flex-wrap items-center justify-between">
                  <div className="flex items-center w-full mb-2 sm:mb-0 sm:w-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="mr-3 w-7 h-7"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                      />
                    </svg>
                    <div className="flex flex-col">
                      <span>{file.fileName}</span>

                      <div className="text-xs">
                        <span className="ml-2">
                          {formatDateTime(file.createdAt)}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-between w-full mb-2 sm:mb-0 sm:w-auto">
                    <div className="flex items-center">
                      <ArrowDownTrayIcon className="w-5 h-5 mr-3 cursor-pointer" />
                    </div>
                    <div className="flex items-center">
                      <TrashIcon
                        className="w-4 h-4 cursor-pointer"
                        onClick={() =>
                          openModal("Modal1", {
                            text: `Da li ste sigurni da želite da obrišete fajl ${file.fileName}?`,
                            close: closeModal,
                            confirm: () => {
                              dispatch(deleteTaskFile(file.fileName)).then(()=>dispatch(getTaskById({ id: taskDetails?.task?.id })));
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupFileUpload;
