import React from "react";
import { Link } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const CardWithImage = ({
  title,
  shortDescription,
  allowed,
  onClick,
  img_path,
  progressBarPercentage,
}) => {
  return (
    <div className="relative flex flex-col overflow-hidden border border-gray-200 rounded-lg shadow bg-primary-55 dark:bg-primary-800 dark:border-gray-800">
      <div className="cursor-pointer" onClick={onClick}>
        {!allowed && (
          <>
            <div className="absolute top-0 left-0 z-0 w-full h-full bg-gray-600 rounded-md opacity-50" />
            <div className="absolute flex items-end justify-center w-full h-full pb-3 z-1">
              <button className="button-info">Informiši se o kupovini</button>
            </div>
          </>
        )}
        <Link href="#">
          <img
            className="w-full lg:h-60 rounded-t-md md:h-44"
            src={require(`../../assets/images/GDPR.jpg`)}
            alt=""
          />
        </Link>
        <div className="flex flex-col flex-grow p-5">
          <Link href="#">
            <div className="flex justify-between w-full mb-2">
              <h5 className="items-center flex-auto text-2xl font-bold tracking-tight text-gray-900 dark:text-white ">
                {title}
              </h5>
              <span className="w-7 h-7">
                <ChevronRightIcon />
              </span>
            </div>
          </Link>
          <div className="flex flex-auto mb-4 ">
            <p className="font-normal text-gray-700 dark:text-gray-400">
              {shortDescription}
            </p>
          </div>
        </div>
      </div>
      {allowed && (
        <div className="mt-auto">
          <div className="flex-grow w-full p-2 mb-4">
            <ProgressBar percentage={progressBarPercentage} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardWithImage;
