import { useState } from "react";

const usePagination = (initialPage = 1, initialPageSize = 9) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize] = useState(initialPageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getTotalPages = (data) =>
    data ? Math.ceil(data.length / pageSize) : 0;

  return {
    currentPage,
    handlePageChange,
    getTotalPages,
    pageSize,
  };
};

export default usePagination;
