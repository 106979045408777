import apiService from "./apiService";
// get all completed tasks for dashboard graph
const fetchTasksDashboard = async () => {
  const response = await apiService.getData("tasks/dashboard");
  const completedTasks = response?.data?.completedTasks;
  return completedTasks;
};

export const completedTaskService = {
  fetchTasksDashboard,
};
