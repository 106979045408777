import { useState, useEffect, useMemo } from "react";

//This function is a recursive search function that checks if a given item contains the search term (term).
const deepSearch = (item, term) => {
  if (typeof item === "string") {
    return item.toLowerCase().includes(term.toLowerCase());
  } else if (Array.isArray(item)) {
    return item.some((value) => deepSearch(value, term));
  } else if (typeof item === "object" && item !== null) {
    return Object.values(item).some((value) => deepSearch(value, term));
  }
  return false;
};

const useSearch = (initialList, config) => {
  // State for the search term
  const [searchTerm, setSearchTerm] = useState("");
  // Function to update the searchTerm state variable when called.
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  // Memoized filtered data .. receives function as first argument and
  // other dependencies as second
  //Functions which is passed to useMemo is triggered when dependency is changed
  const filteredData = useMemo(() => {
    return initialList.filter((item) => {
      //Checks if any key in config object fulfills certain requirement
      return Object.keys(config).some((key) => {
        const searchKey = config[key];
        const searchValue = item[searchKey];
        return deepSearch(searchValue, searchTerm);
      });
    });
  }, [initialList, config, searchTerm]);

  // Effect to update filtered data when the search term changes
  useEffect(() => {
    // Call handleSearch directly here
    handleSearch(searchTerm);
  }, [searchTerm]);

  // Return the state and search function
  return { searchTerm, handleSearch, filteredData };
};

export default useSearch;
