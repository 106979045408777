// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../api/slices/authSlice";
import { persistStore, persistReducer, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage"; // default: localStorage
import projectReducer from "../api/slices/projectSlice";
import memberReducer from "../api/slices/memberSlice";
import requirementReducer from "../api/slices/requirementSlice";
import taskReducer from "../api/slices/taskSlice";
import lawsReducer from "../api/slices/lawsSlice";
import frameworkReducer from "../api/slices/frameworkSlice";
import standardReducer from "../api/slices/standardSlice";
import filesReducer from "../api/slices/filesSlice";
import controlReducer from "../api/slices/controlSlice";
import dashboardReducer from "../api/slices/dashboardSlice";
import documentReducer from "../api/slices/documentSlice";
import completedTaskReducer from "../api/slices/completedTasksDashboardSlice";
import rolesReducer from "../api/slices/rolesSlice";
import tasksForControlReducer from "../api/slices/tasksForControlSlice";
import settingsReducer from "../api/slices/settingsSlice";
import keyReducer from "../api/slices/keySlice";

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage,
};
// Kreiranje perzistentnog reducera
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    project: projectReducer,
    member: memberReducer,
    requirement: requirementReducer,
    task: taskReducer,
    laws: lawsReducer,
    standard: standardReducer,
    framework: frameworkReducer,
    files: filesReducer,
    control: controlReducer,
    dashboard: dashboardReducer,
    documents: documentReducer,
    completedTask: completedTaskReducer,
    roles: rolesReducer,
    tasksForControl: tasksForControlReducer,
    settings: settingsReducer,
    //keys: keyReducer,
    devTools: process.env.NODE_ENV !== "production", // Enable only in development
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
