// projectSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { requirementService } from "../services/requirementService";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchRequirements = createAsyncThunk(
  "/requirements/get",
  async (thunkAPI) => {
    try {
      return await requirementService.getRequirements();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const requirementSlice = createSlice({
  name: "requirement",
  initialState: {
    requirementData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequirements.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRequirements.fulfilled, (state, action) => {
        state.loading = false;
        state.requirements = action.payload;
      })
      .addCase(fetchRequirements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default requirementSlice.reducer;
