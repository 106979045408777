import React, { useEffect } from "react";
import ListOfElements from "../../../common/ListOfElements";
import { useDispatch, useSelector } from "react-redux";
import { fetchFrameworks } from "../../../../api/slices/frameworkSlice";

const Frameworks = () => {
  const dispatch = useDispatch();
  const { frameworkList } = useSelector((state) => state.framework);

  useEffect(() => {
    if (!frameworkList) {
      dispatch(fetchFrameworks());
    }
  }, [frameworkList]);

  if (frameworkList) {
    return <ListOfElements elements={frameworkList} typeName={"Okviri"} />;
  }
};

export default Frameworks;
