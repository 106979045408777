import React, { useEffect, useState } from "react";
import { ButtonWithArrow } from "../../../common/Button";
import { useModal } from "../../../../context/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "../../../common/ToggleButton";
import {
  changeControlStatus,
  deleteControl,
  toggleControlStatus,
} from "../../../../api/slices/controlSlice";
import { TextAreaComment } from "../../../common/FormComponents";
import { fetchMembers } from "../../../../api/services/memberService";
import InfoCircle from "../../../common/InfoCircle";

//Similar component to ProjectInfo component, it shall be taken into consideration to make generic
//component for this part, not to have diffrent components, since they are pretty similar
//it is separeted to make it easier in the future to make it diffrent
const ControlInfo = ({ control }) => {
  const { openModal, closeModal } = useModal();

  const dispatch = useDispatch();
  const { roleName: userRole } = useSelector((state) => state.auth.user?.role);
  const { membersData, loadingMembers } = useSelector((state) => state.member);
  //fetch members for dropDown options
  useEffect(() => {
    {
      if (!membersData) {
        dispatch(fetchMembers());
      }
    }
  }, [membersData]);

  //check currentUser
  const currentUser = useSelector((state) => state.auth.user);
  const currentUserName = `${currentUser?.firstName} ${currentUser?.lastName}`;
  //=====================logic for enable-disable control==========================
  const handleToggle = () => {
    if (!control.applicable) {
      openModal("Modal1", {
        text: `Obeležavanjem ove kontrole na “primenjivo” prethodni  uneti podaci o neprimenjivosti će biti uklonjeni. Da li sigurno želite da nastavite?`,
        close: closeModal,
        type: "success",
        id: id,
        confirm: () =>
          dispatch(toggleControlStatus({ data: { values: "test", id: id } })),
      });
    } else {
      openModal("ModalAdd", {
        content: [
          {
            name: "rationale",
            label: "Razlog za neprimenjivost kontrole:",
            type: "text",
          },
        ],
        close: closeModal,
        type: "addRationale",
        id: id,
      });
    }
  };

  //delete control by id
  const deleteCon = (id) => {
    console.log("brisanje kontrole");
    dispatch(deleteControl({ id }));
  };

  const { id, title, description, status, author, assignees, isActive } =
    control;

  //options for DropDown for changeAutor control
  const options = [];
  if (!loadingMembers && membersData) {
    options.push(
      ...membersData.map((member) => ({
        value: member.id,
        label: member.firstName + " " + member.lastName,
      }))
    );
  }

  //options for DropDown update control status
  const optionsUpdateStatus = [
    { value: "In progress", label: "In progress" },
    { value: "Aborted", label: "Aborted" },
    { value: "Finished", label: "Finished" },
  ];

  return (
    <div class="h-full bg-white rounded-lg  dark:bg-primary-800 p-4 overflow-auto ">
      <div class="">
        <div>
          <div className="flex justify-between py-1 text-sm">
            <span className="text-lg font-bold">{title}</span>

            {userRole === "admin" && (
              // <ToggleButton onToggle={handleToggle} isActive={isActive} />
              <div className="flex items-center justify-between">
                <InfoCircle
                  type="info"
                  text="Klikom na dugme možete menjati primenljivost kontrole"
                />

                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={control.applicable}
                    onChange={() => handleToggle(control.applicable)}
                  />
                  <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            )}
          </div>
          <p className="py-1 text-sm">
            <span className="text-sm font-light ">{description}</span>
          </p>
          <p className="py-1 text-sm">
            <span className="font-medium">Status:</span> &nbsp;
            <span className="font-light max-lg:text-base max-md:text-sm ">
              {status}
            </span>
          </p>
          <p className="py-1 text-sm">
            <span className="font-medium">Vlasnik kontrole:</span> &nbsp;
            <span className="font-light max-lg:text-base max-md:text-sm ">
              {assignees[0]?.firstName === undefined
                ? "Zadužena osoba nije dodeljena"
                : `${assignees[0]?.firstName} ${assignees[0]?.lastName}`}
            </span>
          </p>
        </div>
        <>
          {control?.applicable ? (
            <>
              {/* {author.username !== "Complit" && author.username !== "" ? ( */}
              <>
                <p class="py-1 font-normal text-gray-700 dark:text-gray-400"></p>
                {userRole === "admin" &&
                  `${currentUser?.firstName} ${currentUser?.lastName}` ===
                    `${author?.firstName} ${author?.lastName}` && (
                    <ButtonWithArrow
                      text="Izmeni status kontrole"
                      type="info"
                      onClick={() =>
                        openModal("ModalAdd", {
                          content: [
                            {
                              name: "status",
                              label: "Status kontrole",
                              type: "dropDown",
                            },
                          ],
                          close: closeModal,
                          type: "updateControl",
                          options: optionsUpdateStatus,
                          selected: optionsUpdateStatus[0],
                          id: id,
                        })
                      }
                    />
                  )}
                {userRole === "admin" &&
                  `${currentUser?.firstName} ${currentUser?.lastName}` ===
                    `${author?.firstName} ${author?.lastName}` && (
                    <ButtonWithArrow
                      text="Izmeni vlasnika kontrole"
                      type="info"
                      onClick={() =>
                        openModal("ModalAdd", {
                          content: [
                            {
                              name: "author",
                              label: "Vlasnik kontrole",
                              type: "dropDown",
                            },
                          ],
                          close: closeModal,
                          type: "changeControlOwner",
                          options: options,
                          id: id,
                          removeAssignees: assignees[0].id,
                        })
                      }
                    />
                  )}
                {userRole === "admin" &&
                  assignees[0]?.firstName === undefined && (
                    <button
                      className="text-white rounded-md button-danger bg-primary-750 dark:bg-primary-750 hover:bg-gray-700 dark:hover:bg-gray-700"
                      onClick={() =>
                        openModal("ModalAdd", {
                          content: [
                            {
                              name: "newAssignees",
                              label: "Vlasnik kontrole",
                              type: "dropDown",
                            },
                          ],
                          close: closeModal,
                          type: "addAControlOwner",
                          options: options,
                          id: id,
                        })
                      }
                    >
                      + Dodaj vlasnika
                    </button>
                  )}
                {userRole === "admin" &&
                  `${currentUser?.firstName} ${currentUser?.lastName}` ===
                    `${author?.firstName} ${author?.lastName}` && (
                    <button
                      className="ml-2 button-danger"
                      type="button-danger"
                      onClick={() =>
                        openModal("Modal1", {
                          text: `Da li ste sigurni da želite da obrišete kontrolu "${title}"?`,
                          close: closeModal,
                          confirm: () => deleteCon(control.id),
                        })
                      }
                    >
                      Obriši kontrolu
                    </button>
                  )}
              </>
            </>
          ) : (
            <div className="flex flex-col items-center font-semibold">
              <p className="mb-2 text-center">Kontrola nije primenljiva!</p>
              <p className="text-center">
                Razlog neprimenjivosti: {control?.rationale}
              </p>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default ControlInfo;
