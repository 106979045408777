import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { standardService } from "../services/standardService";

export const fetchStandards = createAsyncThunk(
  "/standards/get",
  async (thunkAPI) => {
    try {
      return await standardService.fetchStandards();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

export const getStandardById = createAsyncThunk(
  "/standardById/get",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return await standardService.getStandardById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

export const createStandard = createAsyncThunk(
  "/standards/get",
  async (data, thunkAPI) => {
    try {
      return await standardService.createStandard(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

export const updateStandard = createAsyncThunk(
  "/standards/updateById",
  async (data, thunkAPI) => {
    const { id, standardData } = data;
    try {
      return await standardService.updateStandard(id, standardData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

export const deleteStandard = createAsyncThunk(
  "/standards/deleteById",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return await standardService.deleteStandard(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

const standardSlice = createSlice({
  name: "standard",
  initialState: {
    standardData: null,
    standardList: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStandards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStandards.fulfilled, (state, action) => {
        state.loading = false;
        state.standardList = action.payload;
      })
      .addCase(fetchStandards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStandardById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStandardById.fulfilled, (state, action) => {
        state.loading = false;
        state.standardDetails = action.payload;
      })
      .addCase(getStandardById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default standardSlice.reducer;
