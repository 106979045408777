import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import {
  composeValidators,
  requiredFieldValidation,
  mustBeString,
  areDatesConsist,
} from "../../utils/helpers/validations";
import { InputField, RenderDatePicker } from "../common/FormComponents";
import { useModal } from "../../context/ModalContext";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { LockOpenIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { DateConsistencyError } from "../../errors/FormValidationErrorField";
import { useDispatch } from "react-redux";
import { updateTask } from "../../api/slices/taskSlice";
import { changeControlStatus } from "../../api/slices/controlSlice";

const ModalViewEditProject = ({ data, isOpen, index }) => {
  const { content, close, type, id } = data;

  const dispatch = useDispatch();

  const [disableInput, setDisabledInput] = useState(true);

  const { openModal, closeModal } = useModal();
  //Function which is being called after data update is confirmed
  const updateAfterConfirmation = (values) => {
    console.log("type", type);
    switch (type) {
      case "project":
        console.log("project u svicu");
        break;
      case "task":
        console.log("task u svicu");
        break;
      case "updateTask":
        values.id = id;
        dispatch(updateTask({ taskData: values }));
        closeModal();
        return;

      default:
        console.log("jbg");
        break;
    }
  };

  if (!isOpen) return null;

  //On form submit, do action defined below
  const onSubmit = async (formValues) => {
    openModal("Modal1", {
      text: `Da li ste sigurni da želite da izvršite izmene?`,
      close: closeModal,
      confirm: () => updateAfterConfirmation(formValues),
      type: "success",
    });
  };

  //Create Array for initialValues
  const contentUpdated = content.map((item) => ({
    [item.name]: item.value,
  }));
  //Reduce it to object
  const initialValues = contentUpdated.reduce((acc, item) => {
    return { ...acc, ...item };
  }, {});
  console.log("intial values");
  console.log(initialValues);

  const renderData = () => {
    return (
      <>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} className="p-4 text-gray-400">
              <div className="flex flex-col p-5 ">
                {content.map((item, index) => (
                  <>
                    {/* Render field names on Serbian.. due to validate function it had to be passed as startDate and endDate */}
                    <div key={index} className="relative">
                      <p className="font-bold ">{item.label}</p>
                      {/* NA OSNOVU TIPA POLJA MORACE DA SE GENERISE FIELD, NE MOZE SVE NA INPUT, NPR DATUM... */}
                      {item.type === "date" ? (
                        <>
                          <Field
                            name={item.name}
                            component={RenderDatePicker}
                            required={true}
                            validate={(value, allValues) =>
                              areDatesConsist(value, allValues)
                            }
                            defaultValue={new Date(item.value)}
                            disabled={disableInput}
                          />
                          <DateConsistencyError name={item.name} />
                          <div className="absolute right-2 top-12">
                            <div className="w-5 h-5">
                              {disableInput ? (
                                <LockClosedIcon />
                              ) : (
                                <LockOpenIcon />
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        item.type === "text" && (
                          <>
                            <Field
                              name={item.name}
                              component={InputField}
                              // type="text"
                              required={true}
                              validate={composeValidators(
                                mustBeString,
                                requiredFieldValidation
                              )}
                              defaultValue={item.value}
                              icon={true}
                              disabled={disableInput}
                            />
                            <div className="absolute right-2 top-12">
                              <div className="w-5 h-5">
                                {disableInput ? (
                                  <LockClosedIcon />
                                ) : (
                                  <LockOpenIcon />
                                )}
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                  </>
                ))}
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  className={`${
                    disableInput ? "button-info" : "button-danger"
                  }`}
                  onClick={
                    disableInput
                      ? () => setDisabledInput((prev) => !prev)
                      : () => {
                          setDisabledInput((prev) => !prev);
                          form.reset();
                        }
                  }
                >
                  {disableInput ? "Uredi" : "Odustani"}
                </button>
                {!disableInput && (
                  <button
                    type="submit"
                    className="ml-2 rounded-md button-success"
                  >
                    Sačuvaj izmene
                  </button>
                )}
              </div>
            </form>
          )}
        />
      </>
    );
  };

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
      <div className="relative w-full max-w-3xl max-h-full p-4 animate-control">
        <div className="relative w-full bg-white rounded-lg shadow dark:bg-primary-800">
          <button
            type="button"
            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={() => close(index)}
          >
            <span className="w-6 ">
              <XMarkIcon />
            </span>
          </button>
          {renderData()}
        </div>
      </div>
    </div>
  );
};

export default ModalViewEditProject;
