import apiService from "./apiService";

//GET
const fetchFrameworks = async () => {
  const response = await apiService.getData(`frameworks`);
  //temporary solution
  const frameworks = response.data?.frameworks?.map((x) => ({
    ...x,
    imgName: "GDPR",
  }));
  return frameworks;
};

const getFrameworkById = async (id) => {
  const response = await apiService.getData(`frameworks/${id}`);
  return response?.data?.framework;
};

export const frameworkService = {
  fetchFrameworks,
  getFrameworkById,
};
