import React, { useEffect, useState } from "react";

const TypeWriterWithTitleAndArray = ({ data, delay, infinite }) => {
  // State variables to keep track of current indices
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);

  // Extract the current item from the data array
  const currentItem = data[currentItemIndex];
  const { title, text } = currentItem;

  // Effect hook for handling the typewriter animation logic
  useEffect(() => {
    let timeout;

    // Check if there are characters left in the current text
    if (currentCharIndex < text[currentTextIndex].length) {
      // If yes, set a timeout to add the next character after the specified delay
      timeout = setTimeout(() => {
        setCurrentCharIndex((prevIndex) => prevIndex + 1);
      }, delay);
    } else if (currentTextIndex < text.length - 1) {
      // If the current text is completed, move to the next text in the array
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => prevIndex + 1);
        setCurrentCharIndex(1);
      }, delay);
    } else if (currentItemIndex < data.length - 1) {
      // If the current item is completed, move to the next item in the array
      setTimeout(() => {
        setCurrentItemIndex((prevIndex) => prevIndex + 1);
        setCurrentTextIndex(0);
        setCurrentCharIndex(1); //Starts from second char
      }, delay);
    } else if (infinite) {
      // If all items are completed and infinite is true, restart from the beginning
      setTimeout(() => {
        setCurrentItemIndex(0);
        setCurrentTextIndex(0);
        setCurrentCharIndex(1);
      }, delay);
    }

    // Cleanup function to clear the timeout
    return () => clearTimeout(timeout);
  }, [
    currentCharIndex,
    currentTextIndex,
    currentItemIndex,
    data,
    delay,
    infinite,
    text,
  ]);

  // JSX rendering - Display title and animated text
  return (
    <div>
      <p className="text-7xl">{title}</p>
      <p className="text-5xl">
        {text[currentTextIndex].substring(0, currentCharIndex)}
      </p>
    </div>
  );
};
export default TypeWriterWithTitleAndArray;
