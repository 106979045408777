import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMembers } from "../../api/services/memberService";
import { fetchTasksDashboard } from "../../api/slices/completedTasksDashboardSlice";
import { fetchRoles } from "../../api/slices/rolesSlice";
import { fetchDashboard } from "../../api/slices/dashboardSlice";
import { useModal } from "../../context/ModalContext";
import TableRowSelection from "../common/tables/TableRowSelection";
import Loader from "../common/Loader";

const Employees = () => {
  const dispatch = useDispatch();
  const { membersData, loadingMembers } = useSelector((state) => state.member);
  const { openModal } = useModal();

  useEffect(() => {
    //get completed tasks
    dispatch(fetchTasksDashboard());
    dispatch(fetchRoles());
    dispatch(fetchDashboard());
  
  }, [dispatch]);


  useEffect(() => {
    dispatch(fetchMembers());
  }, [dispatch]);

  const headers = [
    { name: "ID", key: "id", sortable: false, filter: false },
    { name: "Ime", key: "firstName", sortable: true, filter: false },
    { name: "Prezime", key: "lastName", sortable: true, filter: false },
    { name: "Rola", key: "role.roleName", sortable: false, filter: true },
    { name: "Status", key: "status", sortable: false, filter: true },
  ];

  //Trigger this function in case that deletation of team member is confirmed
  // const confirmDelete = (item) => {
  //   console.log("Confirm" + item);
  // };

  //Hardcoded values for user roles
  const roleOptions = [
    { value: 1, label: "Administrator" },
    { value: 2, label: "Koordinator" },
  ];

  if (loadingMembers || !membersData) {
    console.log("LOADING ODJE" + loadingMembers);

    return <Loader />;
  }

  return (
    <div className="flex flex-col w-full h-full p-4 ">
      <div className="px-6">
        {/* <Table
          headers={headers}
          data={data}
          buttons={buttons}
          headerNames={headerNames}
          role={userRole}
          searchText="listu zaposlenih"
          tableTitle="Članovi moje organizacije"
        /> */}
        <TableRowSelection
          data={membersData}
          headers={headers}
          button="trash"
          itemsPerPage="5"
          searchText="Imenu zaposlenog"
        />

        <div className="py-3">
          <button
            onClick={() =>
              openModal("ModalAdd", {
                content: [
                  { name: "firstName", label: "Ime", type: "text" },
                  { name: "lastName", label: "Prezime", type: "text" },
                  { name: "roleId", label: "Rola", type: "dropDown" },
                  { name: "email", label: "Email", type: "email" },
                ],
                options: roleOptions,
                type: "createUser",
              })
            }
            className="bg-primary-800 dark:bg-primary-800 button-success hover:bg-gray-700 dark:hover:bg-gray-700"
          >
            Dodaj korisnika
          </button>
        </div>
      </div>
    </div>
  );
};

export default Employees;
