import React, { useEffect } from "react";
import Sidebar from "../../components/dashboard_components/Sidebar";
import DashboardPanel from "../../components/dashboard_components/DashboardPanel";
import { SidebarProvider } from "../../context/SidebarContext";
import withAuth from "../../HOCs/withAuth";
import { useDispatch } from "react-redux";
import { logout } from "../../api/slices/authSlice";

const Dashboard = ({ model, projects }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("shouldLogout"))) {
      dispatch(logout());
      localStorage.setItem("shouldLogout", false);
    }
  }, []);

  return (
    <SidebarProvider className="h-screen ">
      <div className="relative flex">
        <Sidebar />
        <DashboardPanel model={model} projects={projects} />
      </div>
    </SidebarProvider>
  );
};

export default withAuth(Dashboard);
