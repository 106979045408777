import React, { useEffect } from "react";
import ListOfElements from "../../../common/ListOfElements";
import { useDispatch, useSelector } from "react-redux";
import { fetchStandards } from "../../../../api/slices/standardSlice";

const Strandards = () => {
  const dispatch = useDispatch();
  const { standardList } = useSelector((state) => state.standard);

  useEffect(() => {
    dispatch(fetchStandards());
  }, [dispatch]);

  console.log("EVO ME U STANDARDIMA I OVO SU DOSTUPNI:" + standardList);

  if (standardList) {
    return <ListOfElements elements={standardList} typeName={"Standardi"} />;
  }
};

export default Strandards;
