// projectSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { controlService } from "../services/controlService";

export const getControlDetails = createAsyncThunk(
  "/control/getById",
  async (data, thunkAPI) => {
    try {
      return controlService.getById(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// get tasks for controls
export const getTasksByControlId = createAsyncThunk(
  "/control/getTasks",
  async (data, thunkAPI) => {
    try {
      return controlService.getTasksByControlId(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// add new control
export const addControl = createAsyncThunk(
  "/control/add",
  async (data, thunkAPI) => {
    try {
      const response = await controlService.addControl(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// change control status using dropDown menu
export const changeControlStatus = createAsyncThunk(
  "/control/changestatus",
  async (data, thunkAPI) => {
    const { id } = data;

    try {
      return controlService.changeStatus(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// change control owner
export const changeControlOwner = createAsyncThunk(
  "/control/changeowner",
  async (data, thunkAPI) => {
    try {
      return controlService.changeOwner(data?.data?.id, {
        data: [
          data?.data.values.newAssignees.author,
          data?.data.values.removeAssignees,
        ],
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// add control owner if it is not defined
export const addControlOwner = createAsyncThunk(
  "/control/changeowner",
  async (data, thunkAPI) => {
    try {
      return controlService.addOwner(data.id, data.data.values);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//enable-disable controls with toggleButton
export const toggleControlStatus = createAsyncThunk(
  "/control/toggle-status",
  async (data, thunkAPI) => {
    console.log("toggle in slice", data.data.values.rationale);

    try {
      return controlService.toggleStatus(
        data.data.id,
        data.data.values.rationale
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete control
export const deleteControl = createAsyncThunk(
  "/control/delete-control",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return controlService.deleteControl(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const controlSlice = createSlice({
  name: "control",
  initialState: {
    controls: null,
    taskList: null,
    successfullUpload: false,
    loading: false,
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addControl.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(addControl.fulfilled, (state, action) => {
        state.loading = false;
        state.successfullUpload = true;
        state.message = "Upload successful!";
        console.log("Upload successful:", action.payload);
      })
      .addCase(addControl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        state.message = `An error occurred: ${state.error}`;
        console.log("An error occurred:", state.error);
      })
      .addCase(getTasksByControlId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTasksByControlId.fulfilled, (state, action) => {
        state.loading = false;
        state.taskList = action.payload;
      })
      .addCase(getTasksByControlId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteControl.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteControl.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleteControl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default controlSlice.reducer;
