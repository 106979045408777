import React, { useContext } from "react";
import CardWithImage from "./CardWithImage";
import useSearch from "../../custom_hooks/useSearch";
import { useNavigate } from "react-router-dom";
import { PageTitleContext } from "../../context/PageTitleContext";
import SearchBar from "./SearchBar";

const ListOfElements = ({ elements, typeName }) => {
  //Navigate initialization
  const navigate = useNavigate();
  const { setPageTitle } = useContext(PageTitleContext);
  setPageTitle(typeName);
  //Initialize searchBar
  const { searchTerm, handleSearch, filteredData } = useSearch(elements, {
    a: "title",
    b: "description",
  });

  const renderElements = (elements) =>
    elements.map((element) => {
      return (
        <CardWithImage
          title={element.title}
          shortDescription={element.shortDesc}
          allowed={element.allowed}
          progressBarPercentage={element.progress}
          // img_path={element.imgName}
          onClick={() => navigate(`/${element.type}-details/${element.id}`)}
        />
      );
    });

  return (
    <div className="px-6 py-8 ">
      <div className="left-0 flex justify-start ml-3">
        <SearchBar
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          type="search"
          searchBy={["Nazivu okvira", "Opisu okvira"]}
        />
      </div>
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4  gap-4 px-4 py-4`}
      >
        {searchTerm ? (
          filteredData.length > 0 ? (
            renderElements(filteredData)
          ) : (
            <div className="py-5">
              <p>Nažalost, traženi podaci ne postoje.</p>
            </div>
          )
        ) : (
          renderElements(elements)
        )}
      </div>
    </div>
  );
};

export default ListOfElements;
