// projectSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addFile = createAsyncThunk(
  "/files/add",
  async (data, thunkAPI) => {
    try {
      return data.file;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const filesSlice = createSlice({
  name: "files",
  initialState: {
    file: null,
    successUpload: false,
    loading: false,
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addFile.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(addFile.fulfilled, (state, action) => {
        state.loading = false;
        state.file = action.payload;
        state.successUpload = true;
        state.message = "Upload successful!";
        console.log("Upload successful:", action.payload);
      })
      .addCase(addFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        state.message = `An error occurred: ${state.error}`;
        console.log("An error occurred:", state.error);
      });
  },
});

export default filesSlice.reducer;
