import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { frameworkService } from "../services/frameworkService";

export const fetchFrameworks = createAsyncThunk(
  "/frameworks/get",
  async (thunkAPI) => {
    try {
      return await frameworkService.fetchFrameworks();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

export const getFrameworkById = createAsyncThunk(
  "/frameworkById/get",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return await frameworkService.getFrameworkById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

const framrworkSlice = createSlice({
  name: "framrwork",
  initialState: {
    framrworkData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFrameworks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFrameworks.fulfilled, (state, action) => {
        state.loading = false;
        state.frameworkList = action.payload;
      })
      .addCase(fetchFrameworks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFrameworkById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFrameworkById.fulfilled, (state, action) => {
        state.loading = false;
        state.frameworkDetails = action.payload;
      })
      .addCase(getFrameworkById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default framrworkSlice.reducer;
