// projectSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  deleteProject,
  fetchProjects,
  postProjectDataAsync,
} from "../services/projectService";
const projectSlice = createSlice({
  name: "project",
  initialState: {
    dataProject: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postProjectDataAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postProjectDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.dataProject = action.payload;
      })
      .addCase(postProjectDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteProject.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default projectSlice.reducer;
