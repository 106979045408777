import JSEncrypt from "jsencrypt";

//============================================= generate private and public key==============================
export const generateKeys = async () => {
  var crypt = new JSEncrypt({ default_key_size: 2048 });
  var PublicPrivateKey = {
    PublicKey: crypt.getPublicKey(),
    PrivateKey: crypt.getPrivateKey(),
  };

  console.log(PublicPrivateKey);

  var publicKey = PublicPrivateKey.PublicKey;

  console.log("|" + publicKey + "|");
  var privateKey = PublicPrivateKey.PrivateKey;

  console.log("|" + privateKey + "|");

  localStorage.setItem("publicKey", publicKey);
  localStorage.setItem("privateKey", privateKey);
};

// =========================generate AES key using Web Crypto API=====================================
export async function generateAesKey() {
  const aesKey = await crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"]
  );
  return aesKey;
}

// ==================================encrypt AES key using RSA public key=============================
export async function encryptAesKeyWithRsa(aesKey, publicKey) {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(publicKey);
  const exportedKey = await crypto.subtle.exportKey("raw", aesKey);
  const aesKeyBase64 = bufferToBase64(exportedKey);
  const encryptedAesKey = jsEncrypt.encrypt(aesKeyBase64);
  if (!encryptedAesKey) {
    throw new Error("AES ključ nije uspeo da se enkriptuje RSA metodom");
  }

  return encryptedAesKey;
}

// =============================convert buffer to Base64=============================================
function bufferToBase64(buffer) {
  const binary = String.fromCharCode(...new Uint8Array(buffer));
  return window.btoa(binary);
}

//================================= encrypt file using AES key ======================================
export async function encryptFileWithAes(file, aesKey) {
  try {
    const iv = window.crypto.getRandomValues(new Uint8Array(12));

    const fileBuffer = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
    const encryptedFile = await crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      aesKey,
      fileBuffer
    );

    const combined = new Uint8Array(iv.length + encryptedFile.byteLength);
    combined.set(iv, 0);
    combined.set(new Uint8Array(encryptedFile), iv.length);
    const encryptedBlob = new Blob([combined], { type: file.type });

    const encryptedFileWithIv = new File([encryptedBlob], file.name, {
      type: file.type,
    });

    return encryptedFileWithIv;
  } catch (error) {
    console.error("Greška prilikom enkripcije fajla:", error.message);
    return null;
  }
}
