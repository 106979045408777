import {
  encryptAesKeyWithRsa,
  encryptFileWithAes,
  generateAesKey,
} from "../../utils/generateKey";
import apiService from "./apiService";
import { createAsyncThunk } from "@reduxjs/toolkit";

//GET all tasks
export const fetchTasks = createAsyncThunk("tasks/fetchTasks", async () => {
  try {
    const response = await apiService.getData("/api/v1/tasks");
    return response;
  } catch (error) {
    throw error;
  }
});

//POST
export const postTask = createAsyncThunk("task/postTask", async (data) => {
  try {
    console.log(data);
    const result = await apiService.postData("/api/v1/tasks", data);
    return result;
  } catch (error) {
    throw new Error("Error during API request");
  }
});
// add comment for task
const addTaskComment = async (data) => {
  const response = await apiService.postData(`tasks/${data.id}/comments`, {
    data,
  });
  return response;
};
// get task comments
const getTaskComments = async (id) => {
  const response = await apiService.getData(`tasks/${id}/comments`);
  return response.data.comments;
};

const getTaskById = async (id) => {
  const response = await apiService.getData(`tasks/${id}`);
  return response.data;
};
// delete task comment
const deleteTaskComment = async (id) => {
  const response = await apiService.deleteData(`comments/${id}`);
  return response.data.comments;
};

const updateTask = async (id, data) => {
  const response = await apiService.putData(`tasks`, data);
  return response;
};

// change task status, using dropDown menu
const changeTaskStatus = async (data) => {
  const parsed = { id: data.id, status: data.status };
  const response = await apiService.putData("tasks/status-change", parsed);
  return response;
};

const deleteTask = async (id) => {
  const response = await apiService.deleteData(`tasks/${id}`);
  return response;
};
//delete file added to task
const deleteFileTask = async (data) => {
  const response = await apiService.deleteData(`files`, data);
  return response.data.comments;
};

// //=============================upload files to createdtask(ovo radi i vrati ovaj deo)=====================================
// const uploadTaskFile = async (data) => {
//   let formData = new FormData();

//   const { tid, name, file } = data;
//   if (data.name) {
//     formData.append("fileDescription", name);
//   }
//   if (data.file) {
//     formData.append("file", file);
//   }
//   const response = await apiService.postFormData(
//     `tasks/${tid.arg.id}/upload`,
//     formData
//   );

//   return response;
// };
// //=============================upload files to createdtask(ovo radi i vrati ovaj deo)=====================================

//====================================OVO JE PRIMER ENKRIPCIJE FAJLA==================================================
const uploadTaskFile = async (data) => {
  let formData = new FormData();

  const aesKey = await generateAesKey();
  const publicKey = localStorage.getItem("serverPubKey");

  const { tid, name, file } = data;
  if (data.name) {
    formData.append("fileDescription", name);
  }
  if (data.file) {
    const encryptedFile = await encryptFileWithAes(file, aesKey);
    console.log("enccc file", encryptedFile);
    formData.append("file", encryptedFile);
  }
  formData.append("taskId", tid.arg.id);

  const encryptedAesKey = await encryptAesKeyWithRsa(aesKey, publicKey);
  formData.append("key", encryptedAesKey);

  const response = await apiService.postFormData(`files`, formData);
  return response;
};
//====================================OVO JE PRIMER ENKRIPCIJE FAJLA==================================================
export const taskService = {
  addTaskComment,
  getTaskById,
  getTaskComments,
  deleteTaskComment,
  changeTaskStatus,
  updateTask,
  deleteTask,
  uploadTaskFile,
  deleteFileTask,
};
