import React from "react";
import { ButtonWithArrow } from "../common/Button";
import { useNavigate } from "react-router-dom";

const ModalAllTasks = ({ data, isOpen, index }) => {
  //Initialize navigate in order to use it for navigating to appropriate task page
  const navigate = useNavigate();

  //Deconstruct all data and functions passed from parent component via data prop in order to
  //use them accordingly in this compoment
  const { allTasks, title, subtitle, close, reqID } = data;

  if (!isOpen) return null;

  //Handle press on task - close modal and navigate to appropriate page
  const handleViewTaskDetails = (e, requestId, taskId) => {
    e.preventDefault();
    close(index);
    navigate(`/project-details/alltasks/${requestId}/${taskId}`);
  };

  const renderTasks = () => {
    return (
      <>
        {allTasks.map((task, index) => (
          <>
            <div key={index} value={task.title} className="mb-2 mr-2">
              <ButtonWithArrow
                text={task.title}
                onClick={(e) => handleViewTaskDetails(e, reqID, task.id)}
                type="info"
              />
            </div>
          </>
        ))}
      </>
    );
  };

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50 ">
      <div className="relative w-full max-w-xl max-h-full p-4">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={() => close(index)}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="flex flex-col p-4 text-center md:p-5">
            <div className="mb-3 text-xl font-bold text-gray-500 dark:text-gray-400">
              {title}
            </div>
            <div className="mb-3 text-gray-500 text-md dark:text-gray-400">
              {subtitle}
            </div>
            <div className="flex flex-wrap mb-3">{renderTasks()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAllTasks;
