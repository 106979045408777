import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { rolesService } from "../services/rolesService";

//get roles for users
export const fetchRoles = createAsyncThunk("/roles/get", async (thunkAPI) => {
  try {
    return await rolesService.fetchRoles();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message;

    return thunkAPI?.rejectWithValue(message);
  }
});

const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    rolesData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.rolesData = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default rolesSlice.reducer;
