// projectSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMembers, memberService } from "../services/memberService";
import PopupSuccess from "../../components/modals/PopupSuccess";
import ReactDOM from "react-dom";

// create new user
export const createUser = createAsyncThunk(
  "/standards/add",
  async (data, thunkAPI) => {
    try {
      return await memberService.createUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);
//deactivate user
export const deactivateUser = createAsyncThunk(
  "/task/deactivate-user",
  async (data, thunkAPI) => {
    try {
      return memberService.deactivateUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const memberSlice = createSlice({
  name: "member",
  initialState: {
    membersData: null,
    loadingMembers: false,
    successCreate: false,
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembers.pending, (state) => {
        console.log("sada sam u pendigu brapice");
        state.loadingMembers = true;
        state.error = null;
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.loadingMembers = false;
        state.membersData = action.payload;
      })
      .addCase(fetchMembers.rejected, (state, action) => {
        state.loadingMembers = false;
        state.error = action.error.message;
      })
      .addCase(createUser.pending, (state) => {
        state.loadingMembers = true;
        state.error = null;
        state.message = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loadingMembers = false;
        state.successCreate = true;
        state.message = "Upload successful!";
        console.log("Upload successful:", action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loadingMembers = false;
        state.error = action.payload || action.error.message;
        state.message = `An error occurred: ${state.error}`;
        console.log("An error occurred:", state.error);
      })
      .addCase(deactivateUser.pending, (state) => {
        state.loadingMembers = true;
        state.error = null;
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.loadingMembers = false;
        state.data = action.payload;
      })
      .addCase(deactivateUser.rejected, (state, action) => {
        state.loadingMembers = false;
        state.error = action.error.message;
      });
  },
});

export default memberSlice.reducer;
