import React, { useContext, useEffect } from "react";
import { PageTitleContext } from "../../../context/PageTitleContext";
import TableRowSelection from "../../common/tables/TableRowSelection";
import { useModal } from "../../../context/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../api/slices/documentSlice";

//hardcoded values
const data = [
  {
    id: "1",
    name: "Politika informacione bezbednosti",
    shortDescription:
      "<b>Politika informacione bezbednosti</b> je dokument koji definiše strategiju i smernice organizacije za zaštitu informacija i upravljanje rizicima u skladu sa ISO 27001 standardom.",
    longDesc:
      "<p><b>Politika informacione bezbednosti</b> je formalni okvir pravila i smernica koje organizacija uspostavlja s ciljem zaštite informacija, IT infrastrukture, podataka i resursa od različitih pretnji kao što su neovlašćeni pristup, gubitak podataka, krađa, oštećenje ili zloupotreba.</p><br><p>Ova politika definiše način na koji organizacija upravlja sigurnošću informacija, uključujući ponašanje zaposlenih i pristup informacijama. Svrha je da osigura poverljivost, integritet i dostupnost informacija koje su ključne za poslovanje. Takođe, politika ima za cilj usklađivanje sa zakonima i standardima, kao i smanjenje rizika povezanih sa IT pretnjama. Politika se bavi zaštitom informacija tako da one ostanu dostupne samo onima koji imaju ovlašćen pristup, osigurava da su podaci tačni i konzistentni, i omogućava da informacije budu dostupne uvek kada su potrebne ovlašćenim korisnicima.<p/><br><p>Ključni deo ove politike je obuka zaposlenih kako bi znali kako pravilno postupati sa podacima i prepoznati potencijalne pretnje poput phishing napada. Politika informacione bezbednosti predviđa odgovor na bezbednosne incidente, kao i pravila o tome kako zaposlenici koriste IT resurse, uključujući lične uređaje i daljinski pristup.Politika mora biti redovno ažurirana kako bi pratila promene u tehnologiji i pretnjama.</p>",
    type: "Politika",
    author: "Nemanja Petrović",
    datePublished: "12.04.2024.",
  },
  {
    id: "2",
    name: "Pravilnik o zaštiti podataka o ličnosti",
    shortDescription:
      "<b>Pravilnik o zaštiti podataka o ličnosti </b>predstavlja osnovu odgovorne i zakonite obrade podataka, pomažući organizacijama da zaštite prava pojedinaca, izbegnu pravne sankcije i izgrade poverenje svojih korisnika. Ova politika osigurava da se podaci o ličnosti obrađuju na siguran i transparentan način, čime se organizacija postavlja kao pouzdan i odgovoran partner u poslovnom svetu.",
    longDesc:
      "<p><b>Pravilnik o zaštiti podataka o ličnosti </b> je formalni dokument koji definiše načela, procedure i pravila koja organizacija mora slediti kako bi obezbedila zaštitu podataka o ličnosti. Njegov glavni cilj je zaštita prava pojedinaca čiji se podaci obrađuju, obezbeđujući da se njihovi lični podaci koriste na zakonit i transparentan način. Svrha pravilnika je da obezbedi poštovanje principa zaštite podataka, kao što su zakonitost, poštenje i transparentnost u obradi podataka o ličnosti.</p><br> <p>Organizacija mora jasno definisati zašto prikuplja podatke, na koji način ih obrađuje i koliko dugo ih čuva, vodeći računa o principu minimizacije podataka, što znači da se prikupljaju samo podaci koji su neophodni za određenu svrhu. Uz to, pojedinci čiji se podaci obrađuju moraju biti informisani o svojim pravima, uključujući pravo na pristup, ispravku, brisanje i prenosivost podataka. Pravilnik takođe definiše pravila o prikupljanju, čuvanju, deljenju i brisanju podataka o ličnosti.</p><br> <p>Podaci moraju biti zaštićeni odgovarajućim tehničkim i organizacionim merama, kao što su enkripcija i kontrola pristupa, kako bi se obezbedila njihova poverljivost, integritet i dostupnost. Takođe, obuhvata pravila o tome ko ima pristup podacima i pod kojim uslovima, s ciljem da se spreči neovlašćeni pristup, gubitak ili zloupotreba podataka.</p>",

    type: "Politika",
    author: "Nemanja Petrović",
    datePublished: "12.04.2024.",
  },
  {
    id: "3",
    name: "Pravilnik o poslovnoj tajni",
    shortDescription:
      "<b>Pravilnik o poslovnoj tajni</b> predstavlja ključnu meru za zaštitu informacija od vitalnog značaja za organizaciju. Ovaj pravilnik pomaže u očuvanju konkurentske prednosti, smanjenju rizika od gubitka poverljivih informacija i jačanju poverenja između organizacije i njenih zaposlenih, partnera i klijenata. U dinamičnom poslovnom svetu, pravilna zaštita poslovnih tajni postaje osnovni element uspešnog poslovanja.",
    longDesc:
      "<p><b>Pravilnik o poslovnoj tajni</b> je dokument koji definiše pravila, procedure i smernice koje organizacija uspostavlja za zaštitu svojih poslovnih tajni. Ovaj pravilnik je ključan za očuvanje konkurentske prednosti i integriteta poslovanja, jer obezbeđuje da informacije koje se smatraju poverljivima ostanu zaštićene od neovlašćenog pristupa, korišćenja ili otkrivanja.<p/><br><p>Osnovna svrha pravilnika je da identifikuje, klasifikuje i štiti informacije koje se smatraju poslovnom tajnom, kao što su strategije, procesi, tehnološka rešenja, marketinški planovi, finansijski podaci, lista klijenata, i drugi podaci koji imaju komercijalnu vrednost i nisu opšte poznati. Politika osigurava da samo ovlašćeni zaposlenici i saradnici imaju pristup tim informacijama, čime se smanjuje rizik od curenja ili zloupotrebe. Pravilnik definiše procedure za identifikaciju poslovnih tajni, uključujući korake koje treba preduzeti da bi se informacije klasifikovale kao poverljive.<p><br><p>Organizacija mora jasno komunicirati koji podaci se smatraju poslovnom tajnom i obezbediti da su svi zaposleni svesni značaja zaštite ovih informacija. Takođe, pravilnik treba da definiše obaveze zaposlenih u vezi sa čuvanjem poslovnih tajni, uključujući pravila o deljenju informacija unutar i izvan organizacije. U okviru pravilnika treba utvrditi i pravne posledice za one koji prekrše pravila o poslovnoj tajni, uključujući disciplinske mere, kao i pravne radnje u slučaju neovlašćenog otkrivanja ili korišćenja poslovnih tajni. Ove mere pomažu da se ojača svest o važnosti zaštite poverljivih informacija među zaposlenima.</p>",
    type: "Politika",
    author: "Nemanja Petrović",
    datePublished: "12.04.2024.",
  },
  {
    id: "4",
    name: "Organizaciona šema i struktura ",
    shortDescription:
      "<b>Organizaciona šema i struktura</b> su fundamentalni elementi uspešnog poslovanja. Oni omogućavaju organizacijama da funkcionišu kao koherentne celine, optimizujući resurse i efikasno ostvarujući svoje ciljeve.",
    longDesc:
      "<p><b>Organizaciona šema i struktura </b>predstavljaju ključne aspekte upravljanja svakim preduzećem ili organizacijom. Oni definišu način na koji su aktivnosti i resursi raspoređeni kako bi se ostvarili poslovni ciljevi. Organizacija može biti formirana na različite načine, a struktura se odnosi na raspodelu odgovornosti i zadataka unutar organizacije.</p><br><p>Organizaciona šema vizuelno prikazuje strukturu organizacije, ističući odnose između različitih jedinica i nivoa upravljanja. Ove šeme omogućavaju jasno razumevanje hijerarhije, od najvišeg nivoa upravljanja do operativnog nivoa, gde se svakodnevne aktivnosti sprovode. Upravni nivo obuhvata izvršne direktore i menadžment, dok srednji nivo čine menadžeri koji koordiniraju i upravljaju radom timova ili odeljenja. Na kraju, operativni nivo obuhvata zaposlene koji izvršavaju zadatke i doprinose ostvarenju ciljeva organizacije.</p><br><p>Značaj organizacione šeme i strukture ogleda se u sposobnosti organizacija da definišu odgovornosti, povećaju efikasnost i održe fokus na strateškim ciljevima. Dobro osmišljena struktura olakšava komunikaciju i donošenje odluka, dok fleksibilna organizacija može brže da se prilagodi promenama u okruženju ili tržištu.</p>",
    type: "Politika",
    author: "Nemanja Petrović",
    datePublished: "12.04.2024.",
  },
  {
    id: "5",
    name: "Akt o proceni rizika na radnom mestu",
    shortDescription:
      "<b>Akt o proceni rizika na radnom mestu</b> je ključni dokument koji pomaže organizacijama da identifikuju, analiziraju i upravljaju rizicima u radnom okruženju. Njegova pravilna implementacija doprinosi očuvanju zdravlja i sigurnosti zaposlenih, smanjenju nezgoda i incidenata, kao i povećanju opšte produktivnosti i zadovoljstva na radu. Ovaj akt takođe osigurava usklađenost sa zakonodavstvom i standardima bezbednosti na radu, čime se organizacija postavlja kao odgovoran i savestan poslodavac.",
    longDesc:
      "<p><b>Akt o proceni rizika na radnom mestu</b> je važan dokument koji se koristi za identifikaciju, analizu i ocenu rizika koji mogu uticati na zdravlje i sigurnost zaposlenih u radnom okruženju.</p><br><p>Ovaj akt je ključan deo upravljanja bezbednošću na radu i obezbeđuje pravni okvir prema kojem organizacije moraju raditi kako bi zaštitile svoje zaposlene. Cilj akta je da se identifikuju potencijalni rizici koji proizlaze iz radnih procesa, opreme, okruženja ili ljudskih faktora.</p><br><p>Procena rizika obuhvata analizu svih aspekata radnog mesta, uključujući fizičke, hemijske, biološke i ergonomskih faktora, kao i organizacione i psihosocijalne aspekte. Ovaj proces omogućava organizaciji da razume koje rizike suočavaju njihovi zaposleni i koliko su ti rizici ozbiljni.</p><p>Akt o proceni rizika obično sadrži detaljne informacije o identifikovanim rizicima, njihovim izvorima, kao i preporučenim merama za smanjenje ili eliminaciju tih rizika.<p><br><p>Pored identifikacije rizika, akt takođe definiše procedure za praćenje i reviziju procene rizika. To uključuje redovne provere i ažuriranja akta kako bi se osiguralo da je u skladu sa promenama u tehnologiji, zakonodavstvu ili poslovnim procesima. Organizacije su obavezne da obezbede da svi zaposleni budu informisani o proceni rizika i da budu uključeni u procese koji se odnose na njihovu bezbednost.</p>",

    type: "Politika",
    author: "Nemanja Petrović",
    datePublished: "12.04.2024.",
  },
];

const Documents = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  setPageTitle("Dokumenta");
  const { openModal } = useModal();

  const dispatch = useDispatch();
  const { documents } = useSelector((state) => state.documents);

  useEffect(() => {
    dispatch(getDocuments());
  }, [dispatch]);

  // Display names for headers
  const headers = [
    { key: "id", name: "ID", sortable: false },
    { key: "name", name: "Naziv", sortable: false },
    { key: "author", name: "Autor", sortable: false },
    { key: "datePublished", name: "Datum objavljivanja", sortable: false },
  ];

  const options = [
    { value: "1", label: "Politika" },
    { value: "2", label: "Procedura" },
    { value: "3", label: "Radno uputstvo" },
  ];

  console.log("data koja treba", documents);
  return (
    <div className="px-6 ">
      {/* <Table
        headers={headers}
        data={data}
        buttons={buttons}
        headerNames={headerNames}
        role={userRole}
        searchText="listu zadataka"
        itemsPerPage="10"
        checkBox="show"
      /> */}
      <TableRowSelection
        data={data}
        headers={headers}
        button="download"
        itemsPerPage="8"
        componentType="document"
        searchText="Nazivu dokumenta"
      />

      <div className="py-3">
        <button
          onClick={() =>
            openModal("ModalAdd", {
              content: [
                {
                  name: "name",
                  label: "Naziv ",
                  value: "",
                  type: "text",
                },
                {
                  name: "shortDescription",
                  label: "Kratak opis dokumenta",
                  value: "",
                  type: "text",
                },
                { name: "type", label: "Tip", type: "dropDown" },
                {
                  name: "author",
                  label: "Autor",
                  type: "text",
                },
                {
                  name: "date",
                  label: "Datum",
                  value: "",
                  type: "date",
                },
                {
                  name: "name",
                  label: "Otpremanje dokumenata",
                  type: "dragAndDrop",
                },
              ],
              type: "addDocument",
              options: options,
            })
          }
          className="bg-primary-800 dark:bg-primary-800 button-success hover:bg-gray-700 dark:hover:bg-gray-700"
        >
          Dodaj dokument
        </button>
      </div>
    </div>
  );
};

export default Documents;
