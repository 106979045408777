import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useSearch from "../../../custom_hooks/useSearch";
import Pagination from "../../common/Pagination";
import usePagination from "../../../custom_hooks/usePagination";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import ContentSideBar from "../ContentSideBar";
import SearchBar from "../../common/SearchBar";
import { useModal } from "../../../context/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import { fetchMembers } from "../../../api/services/memberService";

const Requirements = ({ requirements }) => {
  const { roleName: userRole } = useSelector((state) => state.auth.user?.role);
  const { membersData } = useSelector((state) => state.member);
  const { id } = useParams();

  const [filteredRequirementControls, setFilteredRequirementControls] =
    useState(null);

  const [requirementArea, setRequirementArea] = useState("");
  if (requirements && requirements[0] && !requirementArea) {
    setRequirementArea(requirements[0].area);
  }
  const dispatch = useDispatch();
  useEffect(() => {
    {
      if (!membersData) {
        dispatch(fetchMembers());
      }
    }
  }, [membersData]);

  const { openModal } = useModal();

  //Initialize state for requirement area and filter data based on area
  useEffect(() => {
    const filteredReqControls = requirements.filter(
      (requirement) => requirement.area === requirementArea
    );
    setFilteredRequirementControls(filteredReqControls);
  }, [requirementArea]);

  //Initialize searchBar
  const { searchTerm, handleSearch, filteredData } = useSearch([], {
    a: "title",
  });

  //Initialize use of pagination
  const { currentPage, handlePageChange, getTotalPages, pageSize } =
    usePagination();

  //Initialize state for contentSideBar
  const [selectedControl, setSelectedControl] = useState(null);
  const handleControlClick = (control) => {
    setSelectedControl(control);
  };

  //const renderRequirements for left in-page element
  const renderRequirements = () => {
    return (
      <div>
        <div>
          {requirements &&
            requirements.map((requirement, index) => (
              <>
                {requirement.controls && (
                  <div
                    key={index}
                    className="flex items-center p-4 cursor-pointer bg-primary-55 dark:bg-primary-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-primary-750 hover:rounded-md sm:justify-start"
                    onClick={() => setRequirementArea(requirement.area)}
                  >
                    {requirement.title}
                  </div>
                )}
              </>
            ))}
        </div>
      </div>
    );
  };

  //Values for dropdown menu when adding new control
  const options = [];
  requirements.map((req) => {
    options.push({ value: req.id, label: req.title });
  });

  //Values for dropdown menu when adding new control
  const members = [];
  membersData?.data?.employees?.map((mem) => {
    members.push({
      value: mem.id,
      label: mem.firstName + " " + mem.lastName,
    });
  });

  //Render All Controls in right in-page element
  const renderRequirementControls = (filteredRequirement) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    //Initialize new array for controls. In case that search bar is being used,
    //assign value of "filteredRequirement" which is filteredData array returned from search
    //(IF and ELSE blocks receives diffrent arrays - one in case that search is being used and other in case it does not)
    //if search is not being conducted return list of all controls
    let newArray = null;
    if (searchTerm) {
      newArray = filteredRequirement;
    } else {
      newArray = filteredRequirement[0]?.controls;
    }
    return (
      <div className="max-w-full overflow-x-auto rounded-md ">
        <div className="min-w-[800px] flex flex-col w-full text-center bg-primary-55 dark:bg-primary-800">
          <div className="flex w-full lg:p-6 md:p-3 sm:p-1 ">
            <span className="flex-none w-1/4 px-2 ">Naziv kontrole</span>
            <span className="flex-none w-1/4 px-2">Status</span>
            <span className="flex-none w-1/4 px-2">Primenjivost</span>
            <span className="flex-none w-1/4 px-2">Autor kontrole</span>
          </div>
          {newArray &&
            newArray.slice(startIndex, endIndex).map((control, index) => (
              <div
                key={index}
                className="flex w-full bg-primary-55 dark:bg-primary-800 dark:border-primary-750"
              >
                <div className="flex flex-col w-full rounded-lg">
                  <div className="flex-1 ">
                    <p className="flex flex-col text-gray-900 dark:text-white">
                      <button
                        className="relative flex items-center p-6 border-b border-gray-200 text-primary-800 dark:text-white dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-primary-750"
                        onClick={() => handleControlClick(control)}
                      >
                        <div className="flex items-center w-full">
                          <span className="flex-none w-1/4 px-2">
                            {control.title}
                          </span>
                          <span className="flex-none w-1/4 px-2 ">
                            {control.status}
                          </span>

                          <span className="flex-none w-1/4 px-2">
                            {control.applicable
                              ? "Primenjivo"
                              : "Nije primenjivo"}
                          </span>
                          <span className="flex-none w-1/4 px-2">
                            {control.author?.firstName}{" "}
                            {control.author?.lastName}
                          </span>
                          <span className="w-1/4 ring-0">
                            <ChevronRightIcon className="w-5 h-5" />
                          </span>
                        </div>
                      </button>
                      {/* Add Pagination component */}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="rounded-md">
          <Pagination
            currentPage={currentPage} // Pass the current page
            totalPages={getTotalPages(newArray)} // Calculate total pages
            onPageChange={handlePageChange} // Handle page changes
          />
        </div>
      </div>
    );
  };

  //Return all requirements with control
  return (
    <div className="px-3 pb-8 lg:px-6 md:px-6">
      <div className="flex flex-col w-full ">
        <div className="flex items-center w-full p-4 shadow bg-primary-55 lg:justify-end rounded-t-md dark:bg-primary-800 dark:border-gray-700">
          <>
            <SearchBar
              searchQuery={searchTerm}
              onSearchChange={(e) => handleSearch(e.target.value)}
              type="search"
              searchBy={["Nazivu kontrole"]}
              position="left"
            />
          </>
        </div>
      </div>
      <div className="flex flex-col shadow md:overflow-x-auto lg:h-full md:flex-row dark:border-gray-700 ">
        {/* Sidebar left*/}
        <div className="w-full p-4 mr-1 lg:p-4 bg-primary-55 md:w-1/5 dark:bg-primary-800 md:p-0">
          <div className="flex flex-col justify-between h-full lg:px-4">
            <div className=" dark:bg-primary-800 md:items-start">
              {requirements && renderRequirements()}
            </div>
            {userRole === "admin" && (
              <div className="flex flex-col-reverse mt-2 md:py-3 md:px-3 sm:flex-row md:justify-center">
                <button
                  className="p-5 text-white rounded-md bg-primary-750"
                  onClick={() =>
                    openModal("ModalAdd", {
                      content: [
                        {
                          name: "title",
                          label: "Naziv kontrole",
                          type: "text",
                        },
                        {
                          name: "description",
                          label: "Opis kontrole",
                          type: "text",
                        },
                        {
                          name: "status",
                          label: "Status",
                          type: "text",
                        },
                        {
                          name: "requirementId",
                          label: "Tip kontrole",
                          type: "dropDown",
                        },
                        {
                          name: "assignees",
                          label: "Zadužena osoba",
                          type: "multipleSelect",
                        },
                      ],
                      type: "createControl",
                      options: options,
                      members: members,
                      id: id,
                    })
                  }
                >
                  Dodaj kontrolu +
                </button>
              </div>
            )}
          </div>
        </div>
        {/* Right panel */}
        <div className="flex-grow bg-primary-55 border-primary-750 dark:bg-primary-800">
          {searchTerm ? (
            filteredData.length > 0 ? (
              renderRequirementControls(filteredData)
            ) : (
              <div className="px-4 py-5">
                <p>Nažalost, traženi podaci ne postoje.</p>
              </div>
            )
          ) : (
            filteredRequirementControls &&
            renderRequirementControls(filteredRequirementControls)
          )}
        </div>
      </div>
      {selectedControl && (
        <ContentSideBar
          handleClose={() => setSelectedControl(null)}
          isSelected={selectedControl}
        />
      )}
    </div>
  );
};

export default Requirements;
