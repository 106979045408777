import React from "react";
import MyProfileInfo from "./MyProfileInfo";

const MyProfile = () => {
  return (
    <>
      <div className="">
        <div className="">
          <MyProfileInfo />
        </div>
      </div>
    </>
  );
};

export default MyProfile;
