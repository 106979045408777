import apiService from "./apiService";

//get roles for users
const fetchRoles = async () => {
  const response = await apiService.getData("company/roles");
  const roles = response?.data?.roles;
  return roles;
};

export const rolesService = {
  fetchRoles,
};
