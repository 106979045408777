import apiService from "./apiService";
import { createAsyncThunk } from "@reduxjs/toolkit";

//GET
const fetchRequirements = createAsyncThunk(
  "requirements/fetchRequirements",
  async () => {
    try {
      const response = await apiService.getData("/api/v1/demands");
      return response;
    } catch (error) {
      throw error;
    }
  }
);

//GET
const getRequirements = async () => {
  const data = await apiService.getData(`requirements`);
  console.log("data reqs", data);
  return data?.response?.requirements;
};

//DELETE
const deleteRequirement = createAsyncThunk(
  "requirement/deleteRequirement",
  async (id) => {
    try {
      const response = await apiService.deleteData(
        `/api/v1/demands?demandId=${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const postRequirement = () => {};

export const requirementService = {
  getRequirements,
};
