import React, { createContext, useState, useContext } from "react";
import ModalAllTasks from "../components/modals/ModalAllTasks";
import ModalConfirm from "../components/modals/ModalConfirm";
import ModalViewEditProject from "../components/modals/ModalViewAndEdit";
import ModalAdd from "../components/modals/ModalAdd";
import ModalView from "../components/modals/ModalView";

const ModalContext = createContext();

export function useModal() {
  return useContext(ModalContext);
}

export function ModalProvider({ children }) {
  const [modals, setModals] = useState([]);

  function openModal(type, data) {
    setModals((prevModals) => [...prevModals, { type, data, isOpen: true }]);
  }

  function closeModal(index) {
    setModals((prevModals) => {
      const newModals = [...prevModals];
      newModals[index].isOpen = false;
      return newModals;
    });
  }

  //close all opened modals
  function closeAllModals() {
    const newModals = modals.map((modal) =>
      modal.isOpen === true ? { ...modal, isOpen: false } : modal
    );
    setModals(newModals);
  }

  const modalComponents = {
    //Define your modal components here
    Modal1: ModalConfirm,
    ModalAllTasks: ModalAllTasks,
    Modal3: ModalViewEditProject,
    ModalAdd: ModalAdd,
    ModalView: ModalView,
  };

  return (
    <ModalContext.Provider
      value={{ modals, openModal, closeModal, closeAllModals }}
    >
      {children}
      {modals.map((modal, index) => {
        const ModalComponent = modalComponents[modal.type];
        return <ModalComponent key={index} index={index} {...modal} />;
      })}
    </ModalContext.Provider>
  );
}
