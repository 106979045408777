import apiService from "./apiService";

//GET last login
const fetchLastLogin = async () => {
  const response = await apiService.getData("userdetails/last-login");
  const lastLogin = response?.data;
  return lastLogin;
};
//get all active sessins for setting control
const getActiveSessions = async () => {
  const response = await apiService.getData("userdetails/active-sessions");
  const activeSessions = response?.data;
  console.log("u servisu", activeSessions);
  return activeSessions;
};

//reset all active sessions, excluding the one you are using
const resetActiveSessions = async () => {
  const response = await apiService.getData("userdetails/reset-sessions");
  const resetSessions = response?.data;
  console.log("u servisu", resetSessions);
  return resetSessions;
};

//update password (set new password)
const updatePass = async (data) => {
  const response = await apiService.putData(
    "userdetails/update/password",
    data
  );
  return response;
};

//resset pasword on login page
const resetPassword = async (data) => {
  const response = await apiService.postData(
    "userdetails/reset-password",
    data
  );
  return response;
};

export const settingsService = {
  fetchLastLogin,
  getActiveSessions,
  resetActiveSessions,
  updatePass,
  resetPassword
};
