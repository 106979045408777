export const truncate = (text, maxWords) => {
  if (text.length > 0) {
    //Split text into array
    const words = text.split(" ");
    if (words.length > maxWords) {
      return `${words.slice(0, maxWords).join(" ")} (pročitaj više...)`;
    }
    return text;
  }
  return "Empty string.";
};
// copies all attributes of the passed array and adds a new isExtended attribute
export function mapDataToDTO(data) {
  return data.map((item) => ({
    ...item,
    isExtended: false,
  }));
}

// Handles the click event on element to toggle its extended state.
export function handleClick(id, updatedData, setData) {
  const updatedData2 = updatedData.map((item) => {
    if (item.id === id) {
      return { ...item, isExtended: !item.isExtended };
    }
    return item;
  });
  setData(updatedData2);
}

// Helper function to extract unique values
export const getUniqueValues = (data, key) => [
  ...new Set(data.map((item) => item[key])),
];

// //FORMAT DATE
// export const formatDate = (dateInput) => {
//   let dateObj = new Date(dateInput);

//   if (!isNaN(dateObj)) {
//     let day = dateObj.getDate();
//     day = day < 10 ? "0" + day : day;
//     let month = dateObj.getMonth() + 1;
//     month = month < 10 ? "0" + month : month;
//     const year = dateObj.getFullYear();

//     const resultDate = `${day}/${month}/${year}`;

//     return resultDate;
//   }
// };
