import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { tasksForControlService } from "../services/tasksForControlService";

// get tasks for control
export const fetchTasksForControl = createAsyncThunk(
  "/tasks-for-control/get",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return await tasksForControlService.fetchTasksForControl(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);
// add task for control(without files)
export const addTaskToControl = createAsyncThunk(
  "/task/add-task",
  async (data, thunkAPI) => {
    try {
      return data.file;
      // return tasksForControlService.addTask(data.id, data.values);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const tasksForControlSlice = createSlice({
  name: "files",
  initialState: {
    tasksData: null,
    loadingTasks: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasksForControl.pending, (state) => {
        state.loadingTasks = true;
        state.error = null;
      })
      .addCase(fetchTasksForControl.fulfilled, (state, action) => {
        state.loadingTasks = false;
        state.tasksData = action.payload;
      })
      .addCase(fetchTasksForControl.rejected, (state, action) => {
        state.loadingTasks = false;
        state.error = action.error.message;
      })
      .addCase(addTaskToControl.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTaskToControl.fulfilled, (state, action) => {
        state.loading = false;
        state.successfullUpload = true;
      })
      .addCase(addTaskToControl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default tasksForControlSlice.reducer;
